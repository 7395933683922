<template>
  <b-container fluid>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="creatable"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.backupScheduleSaveModal
                variant="success"
                class="mt-0 ml-1"
                @click="onCreate"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="PlusIcon" /> Thêm mới
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              line-numbers
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="updatable"
                      v-b-modal.backupScheduleSaveModal
                      @click="onEdit(props.row)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Sửa</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="onChangeStatus(props.row)">
                      <feather-icon :icon="props.row.status === 1 ? 'StopCircleIcon' : 'PlayCircleIcon'" />
                      <span class="align-middle ml-50">{{ props.row.status === 1 ? 'Hủy kích hoạt' : 'Kích hoạt' }}</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="deletable"
                      @click="onDelete(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Xóa</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
                <!-- Column: Common -->
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                      đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                    </span>
                  </div>

                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">Hiển thị</span>
                    <b-form-select
                      v-model="itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">bản ghi/trang</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-overlay>

    <BackupScheduleSave
      :schedule="currentSchedule"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton, BCol, BContainer, BDropdown, BDropdownItem, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { STATUSES } from '@/const/status'
import BackupScheduleSave from '@/views/maintenance/backup-schedule/BackupScheduleSave.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'BackupSchedule',
  directives: {
    Ripple,
  },
  components: {
    BDropdownItem,
    BDropdown,
    BackupScheduleSave,
    BFormSelect,
    BContainer,
    BCol,
    BRow,
    BButton,
    BOverlay,
    BPagination,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      columns: [
        {
          label: 'Tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Biểu thức',
          field: 'expression',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      currentSchedule: undefined,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'backupSchedule/schedules',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.BACKUP_SCHEDULE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.BACKUP_SCHEDULE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.BACKUP_SCHEDULE)
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  async created() {
    await this.getDataSourcesFromStore()
  },
  methods: {
    ...mapActions({
      getSchedules: 'backupSchedule/getSchedules',
      changeScheduleStatus: 'backupSchedule/changeScheduleStatus',
      deleteSchedule: 'backupSchedule/deleteSchedule',
    }),
    onCreate() {
      this.currentSchedule = undefined
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getSchedules()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    onEdit(schedule) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = schedule
      this.currentSchedule = rest
    },
    onDelete(schedule) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa lịch sao lưu <span class="text-danger">${schedule.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteSchedule(schedule.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onChangeStatus(dataSource) {
      this.$swal({
        title: `Bạn chắc chắn muốn ${dataSource.status === 1 ? 'hủy kích hoạt' : 'kích hoạt'} lịch <span class="text-danger">${dataSource.name}</span>?`,
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const {
            rowNum,
            originalIndex,
            vgt_id,
            ...rest
          } = dataSource
          const schedule = rest
          schedule.status = schedule.status === 1 ? 0 : 1
          this.isLoading = true
          try {
            const response = await this.changeScheduleStatus(schedule)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
